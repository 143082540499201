
import { Options, Vue } from "vue-class-component";
import QuestionCard from "../components/QuestionCard.vue";
import QuestionItem from "../components/QuestionItem.vue";
import { QuestionType } from "../types/question/question-type";
import { questionListHandler } from "../handlers/question/question-handlers";
import { EnrollmentEnum } from "../types/enum/enrollment-enum";
import { CourseEnrollFormType } from "../types/course/course-type";
import { courseJoinHandler } from "../handlers/course/course-teachers-handlers";
import { isLoggedIn } from "../handlers/handlers-helpers";

@Options({
  components: {
    QuestionCard,
    QuestionItem,
  },
})
export default class Questions extends Vue {
  private questionList: Array<QuestionType> | undefined = [];

  mounted(): void {
    questionListHandler(parseInt(this.$route.params.course_id.toString(), 10))
      .then((questionsList: Array<QuestionType> | void) => {
        this.questionList = questionsList || [];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async handleJoin(enrollment: EnrollmentEnum): Promise<void> {
    if (isLoggedIn()) {
      const payload: CourseEnrollFormType = {
        enrollment_status: enrollment,
      };
      const response = await courseJoinHandler(payload, parseInt(this.$route.params.course_id.toString(), 10));
      if (response === undefined) return;
    }
  }
}
