import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" }
const _hoisted_2 = { class: "container w-full text-center bg-white border-t-0 rounded-xl rounded-t-none" }
const _hoisted_3 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_4 = { class: "whitespace-nowrap" }
const _hoisted_5 = { class: "divide-y divide-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_item = _resolveComponent("question-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question_preview_list, (question, index) => {
              return (_openBlock(), _createBlock(_component_question_item, {
                key: index,
                question: question
              }, null, 8, ["question"]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}