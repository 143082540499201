
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { QuestionType } from "../types/question/question-type";
import Category from "./Category.vue";

@Options({
  components: { Category },
})
export default class QuestionItem extends Vue {
  @Prop() readonly question!: QuestionType;
}
