
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import QuestionItem from "./QuestionItem.vue";
import { QuestionType } from "../types/question/question-type";

@Options({
  components: {
    QuestionItem,
  },
})
export default class QuestionCard extends Vue {
  @Prop() readonly question_preview_list!: Array<QuestionType>;
}
