import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { request } from "@/requests/api-request";
import { CourseEnrollFormType, CourseEnrollStudentFormType, CourseStudentsType } from "@/types/course/course-type";
import { AxiosError } from "axios";
import { getHeaderAuth } from "../handlers-helpers";

export async function courseEnrollHandler(payload: CourseEnrollStudentFormType, course_id: number): Promise<void> {
  return await request(undefined, "PATCH", `course/${course_id}/enroll`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
      }
    });
}

export async function courseJoinHandler(payload: CourseEnrollFormType, course_id: number): Promise<void> {
  return await request(undefined, "POST", `course/${course_id}/enroll`, undefined, payload, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        showAlert("Úspěšně zažádáno o přijetí do kurzu!", "Zpracováváme, vyčkej než to vyřešíme.", AlertSeverities.success);
        return response;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
      switch (error.response?.status) {
        case 401:
          showAlert("Uh-oh", "Pro tuto akci se musíš přihlásit.", AlertSeverities.warn);
          break;
        case 403:
          showAlert("Nemáš přístup", "Na tuto operaci nemáš práva. Nekaž nám naši Fitušku. :c", AlertSeverities.fatal);
          break;
        case 409:
          showAlert("V procesu", "Všechno už se řeší a každou chvílí budeš přidán! a nebo jsi už byl přidán :3", AlertSeverities.info);
          break;
      }
    });
}

export async function courseListStudentsHandler(course_id: number): Promise<Array<CourseStudentsType> | void> {
  return await request(undefined, "GET", `course/${course_id}/students`, undefined, undefined, getHeaderAuth())
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus se přihlásit ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(error);
      }
    });
}
